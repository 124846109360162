import "../css/Footer.css";

export default function Footer() {
  return (
    <>
      <div className="footer-frame">
        <footer className="footer-copy">
          <p>
            This site was built from scratch by the OneAntler himself. For a
            fancy site of your own, visit{" "}
            <a href="https://oneantler.dev">OneAntler Dev</a> or contact{" "}
            <b>
              <a href="mailto: stefan@oneantler.dev"> stefan@oneantler.dev</a>
            </b>
          </p>

          <p>&copy; 2024 Stefan de Clerk</p>
        </footer>
      </div>
      <br></br>
    </>
  );
}
